@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
  font-family: 'Poppins';
}

.button-grandient {
  background: linear-gradient(
    180.54deg,
    #eb6200 -21.14%,
    #eb6804 -17.28%,
    #f29223 11.38%,
    #f8b43c 39.31%,
    #fbcc4d 65.74%,
    #feda58 90.1%,
    #ffe05c 110.75%
  );
}

.dot-flashing {
  position: relative;
  top: 13px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: black;
  }
  50%,
  100% {
    background-color: rgba(105, 105, 105, 0.2);
  }
}

.auth-screen-height {
  height: calc(100vh - 5rem);
}

.main-screen-width {
  width: calc(100vw - 15rem);
}

@media (min-width: 1024px) {
  .main-screen-width {
    width: calc(100vw - 18rem);
  }
}

.generic-scroll::-webkit-scrollbar {
  width: 5px;
}
.generic-scroll::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 20px;
}
.generic-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

.placeholder-gray::placeholder {
  color: #8a8a8a;
}
@media (min-width: 640px) {
  .responsive-D1 {
    font-size: 48px;
    line-height: 57.6px;
  }
  .responsive-D2 {
    font-size: 40px;
    line-height: 48px;
  }
  .responsive-H1 {
    font-size: 32px;
    line-height: 38.4px;
  }
  .responsive-H2 {
    font-size: 24px;
    line-height: 28.8px;
  }
  .responsive-H3 {
    font-size: 20px;
    line-height: 24px;
  }
  .responsive-H4 {
    font-size: 18px;
    line-height: 21.6px;
  }
  .responsive-B1 {
    font-size: 16px;
    line-height: 19.2px;
  }
  .responsive-B2 {
    font-size: 14px;
    line-height: 16.8px;
  }
  .responsive-B3 {
    font-size: 12px;
    line-height: 14.4px;
  }
}

.D1 {
  font-size: 48px;
  line-height: 57.6px;
}
.D2 {
  font-size: 40px;
  line-height: 48px;
}
.H1 {
  font-size: 32px;
  line-height: 38.4px;
}
.H2 {
  font-size: 24px;
  line-height: 28.8px;
}
.H3 {
  font-size: 20px;
  line-height: 24px;
}
.H4 {
  font-size: 18px;
  line-height: 21.6px;
}
.B1 {
  font-size: 16px;
  line-height: 19.2px;
}
.B2 {
  font-size: 14px;
  line-height: 16.8px;
}
.B3 {
  font-size: 12px;
  line-height: 14.4px;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}
textarea[name='reason']::placeholder {
  color: #909090;
}
textarea[name='reason'] {
  resize: none;
}
